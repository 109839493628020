import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './not-found3.css'

const NotFound3 = (props) => {
  return (
    <div className="not-found3-container thq-section-padding">
      <div className="not-found3-max-width">
        <span className="not-found3-text1 thq-body-small">
          {props.content1 ?? (
            <Fragment>
              <span className="not-found3-text2">CA:</span>
            </Fragment>
          )}
        </span>
      </div>
    </div>
  )
}

NotFound3.defaultProps = {
  content1: undefined,
}

NotFound3.propTypes = {
  content1: PropTypes.element,
}

export default NotFound3
