import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import NotFound3 from '../components/not-found3'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>Charva - Bitrock</title>
        <meta property="og:title" content="Charva" />
        <link rel="icon" type="image/png" href="/c-removebg-preview-500h.png" />
      </Helmet>
      <div className="home-navbar navbar-container">
        <div className="max-width">
          <header data-thq="thq-navbar" className="home-navbar-interactive">
            <Link to="/" className="home-navlink">
              <div className="home-logo">
                <img
                  alt="image"
                  src="/c-removebg-preview-500h.png"
                  className="home-image1"
                />
                <span className="home-text10">Charva on Bitrock</span>
              </div>
            </Link>
            <div data-thq="thq-navbar-nav" className="home-desktop-menu">
              <div className="home-links">
                <a
                  href="https://t.me/CharvaBROCK"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-text11 navbarLink"
                >
                  Telegram
                </a>
                <a
                  href="https://x.com/charvabrock"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link10 navbarLink"
                >
                  Twitter/X
                </a>
                <a
                  href="https://charva.medium.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link11 navbarLink"
                >
                  Blog
                </a>
                <a
                  href="https://www.dextools.io/app/en/bitrock/pair-explorer/0x2e104c99565953bef8033f60da8926e414007e82"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link12 button-secondary button"
                >
                  <span>
                    <span>Charts</span>
                    <br></br>
                  </span>
                </a>
                <a
                  href="https://swap.rock-swap.io/#/swap?outputCurrency=0x5baf41672d1aa565ef5fc5555664534274743d26"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link13 button button-primary"
                >
                  Buy Now!
                </a>
              </div>
            </div>
          </header>
        </div>
      </div>
      <NotFound3
        content1={
          <Fragment>
            <span className="home-text15">CA:0x5baF41672D1aA565Ef5Fc5555664534274743d26</span>
          </Fragment>
        }
      ></NotFound3>
      <div className="hero-container section-container">
        <div className="home-max-width2 max-width">
          <div className="home-content1">
            <span className="home-subtitle beforeHeading">Bitrock Chain</span>
            <h1 className="home-title1">
              <span>Charva on </span>
              <span className="home-text17">Bitrock</span>
            </h1>
            <span className="home-description1">
              Charva was made as a Competition Token, offering frequent Buy
              Competitions where participants can win significant rewards. Our
              competitions are fair, fun, and tailored to engage our growing
              community of Charva enthusiasts.
            </span>
            <div className="home-container2">
              <a
                href="https://swap.rock-swap.io/#/swap?outputCurrency=0x5baf41672d1aa565ef5fc5555664534274743d26"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link14 button button-gradient"
              >
                Buy Now!
              </a>
              <a
                href="https://www.dextools.io/app/en/bitrock/pair-explorer/0x2e104c99565953bef8033f60da8926e414007e82"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link15 button button-transparent"
              >
                Charts
              </a>
            </div>
          </div>
          <div className="home-image2">
            <img
              alt="image"
              src="/union-400w.png"
              className="home-graphic-top"
            />
            <img
              alt="image"
              src="/group%2018-1200w.png"
              className="home-image3"
            />
            <img
              alt="image"
              src="/c-removebg-preview-500h.png"
              className="home-hero-image1"
            />
          </div>
        </div>
      </div>
      <div className="home-section1 section-container">
        <div className="home-max-width3 max-width">
          <div className="home-image4">
            <img
              alt="image"
              src="/untitled%20design-1000w.png"
              className="home-hero-image2"
            />
          </div>
          <div className="home-content2">
            <span className="home-text18 beforeHeading">$CHAV</span>
            <h1 className="home-text19">
              <span>Join the</span>
              <br className="home-text21"></br>
              <span className="home-text22">Charva </span>
              <span>revolution.</span>
            </h1>
            <span className="home-text24">
              <span>
                Whether you&apos;re here to compete, invest, or simply enjoy the
                thrill of being part of a dynamic crypto project, Charva ($CHAV)
                is your gateway to excitement and rewards.
              </span>
              <br></br>
            </span>
            <div className="home-container3">
              <a
                href="https://t.me/CharvaBROCK"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link16 button-secondary button bg-transparent"
              >
                Join Now
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2 section-container">
        <div className="home-max-width4 max-width">
          <div className="home-image5">
            <img
              alt="image"
              src="/untitled%20design%20(2)-1200w.png"
              className="home-hero-image3"
            />
          </div>
          <div className="home-content3">
            <span className="home-text27">Tokenomics</span>
            <h1 className="home-text28">
              <span>Here’s what makes Charva ($CHAV) stand out:</span>
              <br></br>
              <br></br>
            </h1>
            <div className="home-step1">
              <div className="home-number1">
                <span className="home-text32">1</span>
              </div>
              <div className="home-container4">
                <span className="home-title2">Total Supply:</span>
                <span className="home-text33">1,000,000</span>
              </div>
            </div>
            <div className="home-step2">
              <div className="home-number2">
                <span className="home-text34">2</span>
              </div>
              <div className="home-container5">
                <span className="home-title3">
                  Initial Liquidity Pool (LP):
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text35">
                  <span>
                    60% of the supply added to the first LP to ensure stability
                    and fairness.
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
            <div className="home-step3">
              <div className="home-number3">
                <span className="home-text38">3</span>
              </div>
              <div className="home-container6">
                <span className="home-title4">
                  <span>Transaction Fees:</span>
                  <br></br>
                </span>
                <span className="home-text41">
                  A 9% fee on every transaction helps fuel the competition
                  ecosystem
                </span>
              </div>
            </div>
            <div className="home-step4">
              <div className="home-container7">
                <span className="home-title5">9% to the Marketing Wallet:</span>
                <span className="home-text42">
                  These funds will be used to reward winners in our
                  competitions, ensuring a thriving and exciting community.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section3 section-container">
        <div className="home-max-width5 max-width">
          <div className="home-content4">
            <span className="home-text43">
              <span>Token Allocation</span>
              <br className="beforeHeading"></br>
            </span>
            <h1 className="home-text46">
              The 1,000,000 total supply of Charva ($CHAV) is carefully
              distributed to ensure stability, rewards, and growth:
            </h1>
            <span className="home-text47">
              60% (600,000 tokens): Added to the first liquidity pool (LP) to
              establish a solid foundation for trading and stability.
            </span>
            <span className="home-text48">
              <span>
                35% (350,000 tokens): Reserved for future liquidity pools (LP),
                ensuring continued growth and sustainability of the project.
              </span>
              <br></br>
            </span>
            <span className="home-text51">
              <span>
                5% (50,000 tokens): Allocated as the developer supply, to
                support ongoing development, operational needs, and future
                enhancements for the Charva ecosystem.
              </span>
              <br></br>
              <br></br>
              <span>
                This strategic allocation ensures a balanced and transparent
                ecosystem, prioritizing both the community and the
                project&apos;s long-term success.
              </span>
              <br></br>
            </span>
          </div>
          <div className="home-image6">
            <img
              alt="image"
              src="/untitled%20design%20(1)-1200w.png"
              className="home-hero-image4"
            />
          </div>
        </div>
      </div>
      <div className="home-section4 section-container">
        <div className="home-max-width6 max-width">
          <div className="home-banner">
            <span className="home-text57 beforeHeading">get started</span>
            <h1 className="home-text58">Join us Now!</h1>
            <div className="home-btns1">
              <a
                href="https://t.me/CharvaBROCK"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link17 button button-transparent"
              >
                Telegram
              </a>
              <a
                href="https://www.dextools.io/app/en/bitrock/pair-explorer/0x2e104c99565953bef8033f60da8926e414007e82"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link18 button button-gradient"
              >
                Chart
              </a>
            </div>
            <div className="home-btns2">
              <a
                href="https://swap.rock-swap.io/#/swap?outputCurrency=0x5baf41672d1aa565ef5fc5555664534274743d26"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link19 button button-gradient"
              >
                Buy
              </a>
              <a
                href="https://x.com/charvabrock"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link20 button button-transparent"
              >
                Twitter/X
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
